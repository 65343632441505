<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
      <GlobalSnack />
    </v-main>
  </v-app>
</template>

<script>
import GlobalSnack from "@/components/common/global-snack.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { GlobalSnack },
  data: () => ({}),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
  },
  watch: {
    settings: {
      handler() {
        const tag = this.settings.analytics.google;
        if (!tag) return;

        const tagManagerScript = document.createElement("script");
        const url = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
        tagManagerScript.setAttribute("src", url);
        document.head.appendChild(tagManagerScript);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", tag);
      },
    },
  },
};
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@each $position in relative, absolute, fixed
  .p-#{$position}
    position: $position

.font-weight-semibold
  font-weight: 600 !important

.theme--light.v-divider
  border-color: map-get($grey, "lighten-3") !important
</style>
